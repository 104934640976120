<script>
import VueQr from "vue-qr";
import {_isMobile, isWechat} from "@/util/device";
import {Dialog} from "vant";

export default {
  name: "customProduct",
  components: {
    VueQr,
  },
  data() {
    return {
      item_id: "",
      custom_user_id: "",
      pageHeight: 0,
      price: "",
      note: "",
      inputNote: "",
      payType: "wx",
      open_id: "",
      appid: "wx555c6690aef40c46",
      state: "qweewq123",
      payTypeList: [],
      noteShow: false,
      qrcodeShow: false,
      qrcode_url: "",
      choosePriceList: [30, 50, 100, 200, 500, 1000],
      pricesGiftProportion: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6], // 价格对应的赠品比例
      productData: null,
      productDataOtherData: null,
      redirect_url: "",
      user_phone: "",
      paying: false
    }
  },
  created() {
    this.user_phone = this.$ls.get("user_phone") || "";
  },
  computed: {
    payPrice() {
      let price = Number(this.price) || 0;
      let final_price = Number(this.productData.final_price) || 0;
      return (price * final_price).toFixed(2);
    },
    giftCount() {
      let price = Number(this.price) || 0;
      let giftProportion = this.pricesGiftProportion[this.choosePriceList.indexOf(price)];
      return Math.floor(price * giftProportion);
    }
  },
  mounted() {
    this.custom_user_id = this.$ls.get("user_id");
    this.redirect_url = this.$route.query.redirect_url ? decodeURIComponent(this.$route.query.redirect_url) : "";
    this.item_id = 32;
    this.price = this.choosePriceList[0];
    this.getProductData();
    this.pageHeight = this.getPageHeight();
    this.initPayType();
    isWechat() && this.initOpenId();
  },
  methods: {
    getProductData() {
      this.$axios.get("/item/getItemInfo", {
        params: {
          item_id: this.item_id, item_user_id: this.custom_user_id
        }
      }).then(res => {
        console.log(res);
        this.productData = res.data;
        this.productDataOtherData = res.other_data;
      })
    },
    onPriceChange(e) {
      if (!e.target.value) return;
      let newValue = e.target.value;
      newValue = newValue.replace(".", "");
      newValue = newValue.replace("-", "");
      // e.target.value = newValue;
      this.price = newValue;
      // console.log(e.target.value, this.price);
    },
    onClickPriceItem(price) {
      this.price = price;
    },
    getPageHeight() {
      // const navbar = document.querySelector(".van-nav-bar");
      // const tabbar = document.querySelector(".van-tabbar");
      // const pageHeight = window.innerHeight;
      // if (!navbar || !tabbar) {
      //   return this.$router.back();
      // }
      // const navbarSize = navbar.getBoundingClientRect();
      // const tabbarSize = tabbar.getBoundingClientRect();
      // return pageHeight - navbarSize.height - tabbarSize.height;
      return window.innerHeight;
    },
    onNoteClick() {
      this.inputNote = this.note;
      this.noteShow = true;
    },
    qrcodeSaveClick() {
      window.location.href = "/user";
    },
    async onPayClick() {
      if (!this.price || this.price < 0) {
        return this.$toast({message: "数量错误！", type: "fail"});
      }
      this.paying = true;
      // if(_isMobile() && !isWechat()) {
      //   return this.$toast({message: "微信支付请在微信或电脑浏览器中使用！", type: "fail"});
      // }
      let form = {
        item_user_id: this.custom_user_id,
        count: this.price,
        item_id: this.item_id,
        pay_channel: this.payType,
        price: this.price,
        notes: this.note,
        open_id: this.open_id,
      }
      try {
        let res = await this.$axios.post(`/item/addOrder`, form);
        if (res.code == 10001 || res.code == 10000) {
          this.$toast(res.msg);
          let data = res.data;
          if (this.payType === "JSAPI") {
            //这里是微信支付的参数
            this.$toast("调起微信支付中");
            this.onBridgeReady(res.data);
            if (typeof WeixinJSBridge == "undefined") {
              if (document.addEventListener) {
                document.addEventListener("WeixinJSBridgeReady", this.onBridgeReady, false);
              } else if (document.attachEvent) {
                document.attachEvent("WeixinJSBridgeReady", this.onBridgeReady);
                document.attachEvent("onWeixinJSBridgeReady", this.onBridgeReady);
              }
            } else {
              this.onBridgeReady();
            }
          } else if (this.payType == "ali_wap") {
            const div = document.createElement("div");
            div.innerHTML = data; //res.data是返回的表单
            document.body.appendChild(div);
            document.forms.alipaysubmit.submit();
          } else if (this.payType == "H5") {
            window.location.href = data.mweb_url + `&redirect_url=${encodeURIComponent("https://mall.zhangcaijing.net/user")}`;
          } else if (this.payType == "NATIVE") {
            this.qrcode_url = data.code_url;
            this.qrcodeShow = true;
          } else {
            this.$toast.fail("暂不支持");
          }
        } else {
          Dialog.alert({
            title: res.msg,
          })
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.paying = false;
      }

    },
    onCancelNote() {
      this.inputNote = "";
      this.noteShow = false;
    },
    onConfirmNote() {
      this.note = this.inputNote;
      this.noteShow = false;
    },
    initPayType() {
      let payTypeList = [];
      if (_isMobile()) {
        if (isWechat()) {
          payTypeList.push({name: "JSAPI", value: "手机微信"});
        } else {
          payTypeList.push({name: "H5", value: "微信支付"});
          payTypeList.push({name: "ali_wap", value: "手机支付宝"});
        }
      } else {
        payTypeList.push({name: "NATIVE", value: "微信扫码"});
      }

      this.payTypeList = payTypeList;
      if (payTypeList.length > 0) {
        this.payType = payTypeList[0].name;
      }
    },
    initOpenId() {
      let openid = this.$ls.get("user_openid");
      if (openid) {
        return this.open_id = openid;
      }
      this.getWxCode();
    },
    getWxCode() {
      let code = this.$route.query.code;
      if (code) {
        this.getOpenId(code);
      } else {
        let redirect_uri = encodeURIComponent(
            "https://mall.zhangcaijing.net/custom-product"
        );
        let url =
            "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
            this.appid +
            "&redirect_uri=" +
            redirect_uri +
            "&response_type=code&scope=snsapi_base&state=" +
            this.state +
            "#wechat_redirect";
        window.location.href = url;
      }
    },
    onBridgeReady(data) {
      var that = this;

      WeixinJSBridge.invoke(
          "getBrandWCPayRequest",
          {
            appId: data.appId, //公众号名称，由商户传入
            timeStamp: data.timeStamp, //时间戳，自1970年以来的秒数
            nonceStr: data.nonceStr, //随机串
            package: data.package,
            signType: data.signType, //微信签名方式：
            paySign: data.paySign, //微信签名
          },
          function (res) {
            that.$toast(res.err_msg);
            if (res.err_msg == "get_brand_wcpay_request:ok") {
              let message = "";
              if (that.redirect_url) {
                message = "支付成功，点击确认跳转";
              } else {
                message = "支付成功,点击确定回到我的订单";
              }
              that.$dialog.alert({
                title: "提示",
                message: message,
                theme: "round-button",
              })
                  .then(() => {
                    if (that.redirect_url) {
                      window.location.href = that.redirect_url;
                    } else {
                      window.location.href = "https://mall.zhangcaijing.net/user";
                    }
                  });
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            } else {
              that.$toast.fail("已取消");
            }
          }
      );
    },
    async getOpenId(code) {
      let res = await this.$axios.get("/wx/getWxOpenId", {
        params: {
          code,
          mp_name: "wcsj",
        },
      });
      if (res.code == 10001) {
        this.$toast.success(res.msg);
        this.open_id = res.data.open_id;
        this.$ls.set("user_openid", res.data.open_id);
      } else {
        this.$toast.fail(res.msg);
      }
    },
    onClickLeft() {
      this.$router.back();
    },
    onClickRight() {
      this.$router.push('/user?showTabbar=2')
    }
  }
}
</script>

<template>
  <div>
    <div class="custom-product flex flex-col bg-gray-200 overflow-y-auto" :style="{height: pageHeight + 'px'}"
         v-if="productData">
      <van-nav-bar title="充值" left-text="返回" right-text="我的订单" @click-left="onClickLeft"
                   @click-right="onClickRight"></van-nav-bar>
      <div class="header flex justify-between items-center px-6 py-12">
        <div class="left space-y-1">
          <h1 class="font-bold" style="font-size: 1.1rem">{{ productData.name }}</h1>
          <p class="text-sm text-gray-600">天下财经，唯真不破</p>
        </div>
        <div class="right">
          <img :src="productData.img_url" alt="" class="w-16 h-16">
        </div>
      </div>
      <div class="content bg-white rounded-tl-2xl rounded-tr-2xl p-6 flex-1 shadow-xl flex flex-col">
        <div>
          <p class="text-center mb-6 pb-6 border-b text-xl">手机号：{{ user_phone }}</p>
        </div>
        <div class="price-choose">
          <div class="flex justify-between items-center mb-4">
            <p>快速选择 <span class="text-sm ml-2">(购买比例：1枚={{ productData.final_price }}元)</span></p>
            <!--            <p class="text-blue-500" @click="">我的订单-->
            <!--              <van-icon name="arrow"></van-icon>-->
            <!--            </p>-->
          </div>
          <div class="grid grid-cols-3 gap-5 mb-6">
            <div v-for="p in choosePriceList" :key="p" @click="onClickPriceItem(p)" :class="{light: price == p}"
                 class="price-item text-center bg-blue-100 rounded shadow py-3">
              {{ p }}枚
            </div>
          </div>
        </div>
        <div class="price-content mb-10">
          <!--        <p class="mb-4">充值数量</p>-->
          <!--        <div class="flex items-center justify-between border-b pb-2 mb-4">-->
          <!--          <img :src="productData.img_url" alt="" class="h-8 object-contain">-->
          <!--          <div class="h-10 flex-1 flex items-end">-->
          <!--            <input disabled type="number" class="text-3xl px-5 w-full" v-model="price" @input="onPriceChange"/>-->
          <!--          </div>-->
          <!--        </div>-->
          <div>
            <div class="flex flex-col">
              <p class="text-red-500 font-bold mb-3" v-if="productDataOtherData && !productDataOtherData.has_coin_order">首充专享：赠送{{ giftCount }}枚，合计{{ price + giftCount }}枚</p>
              <span class="text-black font-bold text-xl">实付：{{ payPrice }} 元</span>
              <!--            <p v-if="productDataOtherData && !productDataOtherData.has_coin_order">新用户专享：{{giftCount}}</p>-->
            </div>
          </div>
        </div>
        <div class="pay-method">
          <p class="mb-4">请选择付款方式</p>
          <div>
            <van-radio-group v-model="payType" class="h-10" direction="horizontal">
              <van-radio v-for="item in payTypeList" :key="item.name" :name="item.name">{{
                  item.value
                }}
              </van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="flex-1"></div>
        <div class="space-y-2">
          <van-button type="info" block @click="onPayClick" :disabled="paying">付款</van-button>
          <!--        <van-button type="default" block @click="onPayClick">我的订单</van-button>-->
        </div>
      </div>
      <van-action-sheet v-model="noteShow" title="付款备注" style="height: 30%">
        <div class="content">
          <div class="mb-3">
            <van-field class="text-xl" autosize show-word-limit type="textarea" placeholder="请填写付款备注，最多50个字"
                       v-model="inputNote" :maxlength="50" clearable></van-field>
          </div>
          <div class="flex items-center justify-center space-x-8">
            <van-button @click="onCancelNote">取消</van-button>
            <van-button type="primary" @click="onConfirmNote">确认</van-button>
          </div>
        </div>
      </van-action-sheet>
      <van-dialog v-model="qrcodeShow" title="请用微信扫码" show-cancel-button @confirm="qrcodeSaveClick()">
        <div class="mx-auto flex justify-center">
          <vue-qr :text="qrcode_url" qid="testid"></vue-qr>
        </div>
      </van-dialog>
    </div>
  </div>
</template>

<style>
.custom-product {
  padding-bottom: 0 !important;
}

.price-item.light {
  @apply bg-red-500 text-white;
}
</style>