var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-2"},[_c('div',{staticClass:"p-2 telcolor"},[_c('van-cell',{attrs:{"title":"订单客服:13107873675","is-link":"","label":"周一至周五9:00-18:00 周六周日9:00-17:00","url":"tel:13107873675"}})],1),_c('van-divider',{style:({
        color: '#1989fa',
        borderColor: '#1989fa',
        padding: '1px 0px',
      })}),_vm._l((_vm.itemList),function(item,index){return _c('van-cell-group',{key:index,attrs:{"border":false}},[_c('van-cell',{attrs:{"title":"订单号 : ","value":item.order_no}}),_c('van-cell',{attrs:{"title":item.item_name,"value":item.status_name,"value-class":"telcolor"}}),_c('van-cell',{attrs:{"title":'已付 : ¥' + item.price,"value":(item.item_id == 32 ? '唯真币：' : '个数：') + item.count + (item.item_id == 32 ? '枚' : '')}}),(item.is_address == 1)?_c('van-cell',{attrs:{"title":"收货地区 : ","value":item.province + '/' + item.city + '/' + item.area}}):_vm._e(),(item.is_address == 1)?_c('van-cell',{attrs:{"title":"详细地址 : ","value":item.address}}):_vm._e(),_c('van-cell',{directives:[{name:"show",rawName:"v-show",value:(item.is_address == 1),expression:"item.is_address == 1"}],attrs:{"title":"顺丰单号 : ","value":item.logistics_num}}),_c('van-cell',{attrs:{"title":"时间 : ","value":item.created_at}}),_c('van-cell',{directives:[{name:"show",rawName:"v-show",value:(item.show_qr_code),expression:"item.show_qr_code"}],staticClass:"qr-title",attrs:{"title":"请持二维码入场"}}),_c('van-cell',{directives:[{name:"show",rawName:"v-show",value:(item.show_qr_code),expression:"item.show_qr_code"}],staticClass:"qr"},[_c('vue-qr',{attrs:{"text":'https://sh.zhangcaijing.net?order_no=' + item.order_no,"margin":0}})],1),_c('van-divider',{style:({
          color: '#1989fa',
          borderColor: '#1989fa',
          padding: '5px 0px',
        })})],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }